<template lang="pug">
  .slot-location(v-if="haveItemsInLocation")
    .slot-location__header {{ location }} -
      b {{ fullDate }}
    .slot-location__content
      .slot-location__part(data-test="slot-location-top" v-if="topActive")
        .slot-location__part-header TOPCBR slots
        .slot-table
          item(v-for="(slot, index) in topCBRItems" :key="index" :item="slot")
          total(:items="topCBRItems")
      .slot-location__part(data-test="slot-location-mijn" v-if="mijnActive")
        .slot-location__part-header MIJNCBR slots
        .slot-table
          item(v-for="(slot, index) in mijnItems" :key="index" :item="slot")
</template>

<script>
import { SLOT_TYPE, ACTIVE_TYPE } from "../core/slots-conts"
import { mapGetters } from "vuex"

export default {
  props: {
    location: String,
    items: Array,
    fullDate: String
  },

  data: () => ({
    ACTIVE_TYPE: ACTIVE_TYPE,
    activeType: ACTIVE_TYPE.ALL
  }),

  computed: {
    ...mapGetters({
      topActive: 'slotsType/topActive',
      mijnActive: 'slotsType/mijnActive'
    }),
    topCBRItems() {
      return this.items.filter(item => item.type === SLOT_TYPE.TOP)
    },

    mijnItems() {
      return this.items.filter(item => item.type === SLOT_TYPE.MIJN)
    },

    haveItemsInLocation() {
      if (this.topActive && this.topCBRItems.length) return true
      if (this.mijnActive && this.mijnItems.length) return true
      return false
    }
  },

  components: {
    item: () => import('./SlotsItem.vue'),
    total: () => import('./SlotsTotal.vue')
  }
}
</script>

<style lang="scss" scoped>
.slot-location {
  &__header {
    padding: 14px;
    background-color: lighten($color: #E5E5E5, $amount: 7);
    font-size: 13px;
    border: 1px solid #000;
  }

  &__content {
    display: flex;
  }

  &__part {
    width: 100%;

    &:first-child {
      border-right: 1px solid #000;
    }

    &-header {
      font-size: 12px;
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid $border-color;
    }
  }
}
</style>

<style lang="scss">
.slot-table {
  &__header {
    padding: 0 8px;
    border-bottom: 1px solid $border-color;
    display: flex;
  }

  &__cell {
    border-right: 1px solid $border-color;
    font-size: 11px;

    &:last-child {
      border-right: unset;
    }

    &_time, &_quant {
      width: 33%;
      text-align: center;
    }
  }

  &__item {
    display: flex;
    border-bottom: 1px solid $border-color;

    &_wrap {
      flex-wrap: wrap;
    }
  }
}
</style>
